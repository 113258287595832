<template>
  <b-container>
    <div class="d-flex justify-content-center mb-1">
      <b-spinner variant="success" label="Spinning" block v-if="loading" style="width: 3rem; height: 3rem"></b-spinner>
    </div>
    <b-row>
      <b-col cols="12">
        <ChartSales :sales="salesOrderList" :totalSales="totalSalesAmount" v-if="!loading" />
      </b-col>
      <b-col cols="12">
        <SalesTable :sales="salesOrderList" v-if="!loading" />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import ChartSales from "./ChartSales.vue";
import SalesTable from "./SalesTable.vue";
import { BContainer, BRow, BCol, BSpinner } from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BContainer,
    BRow,
    SalesTable,
    ChartSales,
    BCol,
    BSpinner,
  },

  data() {
    return {
      totalSalesAmount: 0,
    };
  },
  computed: {
    ...mapGetters("salesOrderModule", {
      salesOrderList: "salesorders",
      loading: "loading",
    }),
  },

  methods: {
    ...mapActions("salesOrderModule", [
      "getSalesOrdersListAction",
    ]),
  },

  async mounted() {
    await this.getSalesOrdersListAction()
      .then(() => {
        let totalSalesAmount = 0;
        this.salesOrderList.forEach(order => {
          totalSalesAmount += order.total;
        });
        this.totalSalesAmount = totalSalesAmount;
      })
      .catch((response) => {
        console.log(response);
      });
  },
};
</script>
