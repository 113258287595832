<template>
  <b-card>
    <h3>Report for the year : {{ year }}</h3>
    <b-table responsive :items="salesData" :fields="fields" class="mb-0">
      
      <template #cell(salesAmount)="data">
        {{ data.item.salesAmount.toFixed(2) }}
      </template>
      <template #cell(revenueAmount)="data">
        {{ data.item.revenueAmount.toFixed(2) }}
      </template>
    </b-table>
  </b-card>
</template>

<script>
import { BTable, BCard } from "bootstrap-vue";

export default {
  components: {
    BTable,
    BCard,
  },
  props: {
    sales: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      year: new Date().getFullYear(),
      fields: [
        { key: "month", label: "Month" },
        { key: "salesQuantity", label: "Sales Quantity" },
        { key: "salesAmount", label: "Sales Amount" },
        { key: "revenueAmount", label: "Revenue Amount" },
      ],
      salesData: this.calculateSalesData(this.sales),
    };
  },
  methods: {
    calculateSalesData(sales) {
      let monthlyData = {};

      sales.forEach(order => {
        let month = new Date(order.issuedDate).getMonth();
        
        if(!monthlyData[month]) {
          monthlyData[month] = { month: this.getMonthName(month), salesQuantity: 0, salesAmount: 0, revenueAmount: 0 };
        }

        order.orderItems.forEach(item => {
          monthlyData[month].salesQuantity += item.qty;
          monthlyData[month].salesAmount += item.qty * item.price;
        });

        monthlyData[month].revenueAmount += order.total;
      });

      return Object.values(monthlyData);
    },
    getMonthName(monthIndex) {
      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      return monthNames[monthIndex];
    }
  },
};
</script>

